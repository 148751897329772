@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.roboto {
  font-family: "Roboto", sans-serif;
}
.manrope {
  font-family: "Manrope", sans-serif;
}

.text-main{
  line-height: 120%;
  letter-spacing: -20px;
  white-space: nowrap;
}

.spanred span{
  color: #B30101;
}

.button_other{
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  background: #B30101;
}

.Dropdown-control {
  cursor: pointer;
  font-style: normal!important;
  font-size: 16px!important;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  background-color: transparent!important;
  border: none!important;
  color: #FFFFFFB2!important;
  text-transform: uppercase!important;
  padding-right:25px!important;
  
}
.Dropdown-menu {
  font-style: normal;
  font-size: 16px!important;
  max-height: 300px!important;
  
}
.Dropdown-arrow {
  border-color: #FFFFFFB2 transparent transparent!important;
  margin-top: 3px;
 
}
.is-open .Dropdown-arrow {
  border-color: transparent transparent #FFFFFFB2!important;
}

.button{
  display: inline-flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: #B30101;
}